<template>
  <div class="v-stack h-stretch">
    <div class="card light">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <label class="text-left required">Main Category:</label>
          <select v-model="categoryMain" @change="onMainCategoryChange($event)">
            <option v-for="category in mainCategories" :key="category">
              {{ category }}
            </option>
          </select>
          <label class="text-left required">Sub Category:</label>
          <select v-model="categorySub">
            <option v-for="category in subCategories" :key="category">
              {{ category }}
            </option>
          </select>
          <label class="text-left required">Name:</label>
          <input v-model="name" type="text" />
          <label class="text-left required">Unit:</label>
          <select v-model="unit">
            <option v-for="unit in units" :key="unit">{{ unit }}</option>
          </select>
          <label class="text-left required">Price:</label>
          <input v-model="price" type="number" />
          <label class="text-left">Comment:</label>
          <input v-model="comment" type="text" />
          <label class="text-left required">Editable Price:</label>
          <input type="checkbox" v-model="editablePrice" />
          <label class="text-left required">Editable Count:</label>
          <input type="checkbox" v-model="editableCount" />
          <label class="text-left required">Owned:</label>
          <input type="checkbox" v-model="inHouse" />
          <div class="h-stack h-end">
            <button @click.prevent="submit()" class="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import constants from "@/constants.js";
const moment = require("moment");

export default {
  props: ["id"],
  data() {
    return {
      categoryMain: "",
      categorySub: "",
      name: "",
      unit: "",
      price: 0,
      comment: "",
      inHouse: true,
      editablePrice: false,
      editableCount: false,
      inStock: 0,
      dateOfPurchase: moment().toISOString(),
      storeDescription: "",
      mainCategories: constants.pricelistMainCategories,
      units: constants.pricelistUnits.map((item) => item.name),
      subCategories: [],
      items: [],
    };
  },
  methods: {
    ...mapActions(["addPricelistItem", "getPricelistItems"]),
    onMainCategoryChange(event) {
      const category = event.target.value;
      if (category == "TECHNIKA") {
        this.subCategories = constants.pricelistSubCategoriesGear;
      }
      if (category == "PERSONÁL / SLUŽBY") {
        this.subCategories = constants.pricelistSubCategoriesServices;
      }
    },
    submit() {
      this.addPricelistItem({
        categoryMain: this.categoryMain,
        categorySub: this.categorySub,
        name: this.name,
        unit: this.unit,
        price: this.price,
        comment: this.comment,
        inHouse: this.inHouse,
        inStock: this.inStock,
        dateOfPurchase: this.dateOfPurchase,
        storeDescription: this.storeDescription,
        editablePrice: this.editablePrice,
        editableCount: this.editableCount,
        pricelist: this.id,
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getPricelistItems()
      .then((items) => {
        this.items = items;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>